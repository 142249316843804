body {
  background-image: url("/img/bg_texture.png");
}

a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

a {
  color: inherit;
}